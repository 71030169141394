import React from 'react';
import "./footer.css";

export default function Footer() {
    return (
        <footer>
            <p><span>&copy;</span> 2021 Daisuke Kawabuchi</p> 
        </footer>
    )
}
